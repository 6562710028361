import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import VueBarcode from "@chenfengyuan/vue-barcode";
import "./style/index.css";
import "./style/style.css";
import "flowbite";
import "sweetalert2/dist/sweetalert2.min.css";
// Import loading stuff
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import("preline");
import "boxicons";
import moments from "./Directives/moment";
import { useLoading } from "vue-loading-overlay";

const $loading = useLoading({});
createApp(App)
  .use(store)
  .use(VueLoading)
  .use(router)
  .use(VueSweetalert2)
  .use($loading)
  .component(VueBarcode.name, VueBarcode)
  .directive("moment", moments)
  .mount("#app");
