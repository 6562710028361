import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/categories_page",
  },
  {
    path: "/login",
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/categories_page",
    name: "CategoriesPage",
    component: () => import("../views/CategoriesPage.vue"),
  },
  {
    path: "/lectures",
    name: "ProductsPage",
    component: () => import("../views/LecturesPage.vue"),
  },

  {
    path: "/sub_categories",
    name: "NewsPage",
    component: () => import("../views/SubCategories.vue"),
  },
  {
    path: "/users_page",
    name: "UsersPage",
    component: () => import("../views/UsersPage.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, _, next) => {
//   if (
//     to?.path != "/login" &&
//     !localStorage["drosy_token"] &&
//     !localStorage["drosy_user"]
//   )
//     next("/login");
//   else {
//     console.log("Ok");
//   }
// });

export default router;
