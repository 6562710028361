import moment from "moment";

const moments = {
  mounted: (el, bind) => {
    const now = moment(new Date());
    const date = bind.value.date;
    const datedif = moment.duration(now.diff(date))._data.days;
    moment.locale("ar");
    if (datedif > 0) {
      el.innerText = moment(bind.value.date).format("Do MMM YYYY - h:mm a");
    } else {
      el.innerText = moment(bind.value.date)
        .startOf(bind.value.format)
        .fromNow();
    }
    setInterval(() => {
      const now = moment(new Date());
      const date = bind.value.date;
      const datedif = moment.duration(now.diff(date))._data.days;
      moment.locale("ar");
      if (datedif > 0) {
        el.innerText = moment(bind.value.date).format("Do MMM YYYY - h:mm a");
      } else {
        el.innerText = moment(bind.value.date)
          .startOf(bind.value.format)
          .fromNow();
      }
    }, 5000);
  },
};

export default moments;
